<template>
    <div>
        <div style="display: flex;flex-direction: row;" v-if="zhengjing()">
            <!--   订单管理中所有已完成订单状态的缴费金额的总计   -->
            <AnalysisCard tooltip="订单管理中所有已完成订单状态的缴费金额的总计" background="#409EFF" title="流水" width="150px"
                          :content="formatMoney(dataAnalysisData.revenue)"></AnalysisCard>
            <!--   所有咨询师的所有状态的正式订单(不算补录订单)数量   -->
            <AnalysisCard tooltip="所有咨询师的所有状态的正式订单(不算补录订单)数量" background="#FF88A7"
                          title="订单总量"
                          :content="formatNumberWithCommas(dataAnalysisData.orderTotal)" unit="个"></AnalysisCard>
            <!--   所有已成交的正式订单的数量   -->
            <AnalysisCard tooltip="所有已成交的正式订单的数量" background="#7B5EEA" title="成单总量"
                          :content="formatNumberWithCommas(dataAnalysisData.orderSuccess)" unit="个"></AnalysisCard>
            <!--   所有直呼双呼的接通的通话总时长   -->
            <AnalysisCard tooltip="所有直呼双呼的接通的通话总时长" background="#F19149" title="总拨打时长"
                          :content="formateTime(dataAnalysisData.calledTotal)"></AnalysisCard>
            <!--   所有直呼双呼的点击拨打次数统计   -->
            <AnalysisCard tooltip="所有直呼双呼的点击拨打次数统计" background="#FF88A7" title="拨打量"
                          :content="formatNumberWithCommas(dataAnalysisData.called)"
                          unit="个"></AnalysisCard>
            <!--   所有拨打机会的接通量的统计   -->
            <AnalysisCard tooltip="所有拨打机会的接通量的统计" background="#7B5EEA" title="接通量"
                          :content="formatNumberWithCommas(dataAnalysisData.connected)" unit="个"></AnalysisCard>
            <!--   所有拨打接通成功>30s的通话数的统计   -->
            <AnalysisCard tooltip="所有拨打接通成功>30s的通话数的统计" background="#F19149" title="有效量"
                          :content="formatNumberWithCommas(dataAnalysisData.calledEffective)" unit="个"></AnalysisCard>
            <!--   所有领取首咨的机会数统计   -->
            <AnalysisCard tooltip="所有领取首咨的机会次数统计" background="#F19149" title="索取首咨总次数"
                          :content="formatNumberWithCommas(dataAnalysisData.receiveFirst)"
                          unit="个"></AnalysisCard>
        </div>
        <el-row style="margin-bottom: 20px;flex-wrap: wrap" v-if="zhengjing()">
            <el-row :gutter="10">
                <el-col :span="4">
                    <el-checkbox v-model="Options.revenue" @change="OptionsChange">流水</el-checkbox>
                </el-col>
                <el-col :span="4">
                    <el-checkbox v-model="Options.repairRevenue" @change="OptionsChange">补录流水</el-checkbox>
                </el-col>
                <el-col :span="4">
                    <el-checkbox v-model="Options.opportunityCount" @change="OptionsChange">当前机会数</el-checkbox>
                </el-col>
                <el-col :span="4">
                    <el-checkbox v-model="Options.receiveFirst" @change="OptionsChange">索取首咨次数</el-checkbox>
                </el-col>
                <el-col :span="4">
                    <el-checkbox v-model="Options.receiveInventory" @change="OptionsChange">索取库存次数</el-checkbox>
                </el-col>
                <el-col :span="4">
                    <el-checkbox v-model="Options.called" @change="OptionsChange">拨打量</el-checkbox>
                </el-col>
                <el-col :span="4">
                    <el-checkbox v-model="Options.wechat" @change="OptionsChange">微信添加量</el-checkbox>
                </el-col>
                <el-col :span="4">
                    <el-checkbox v-model="Options.calledTimes" @change="OptionsChange">拨打频次</el-checkbox>
                </el-col>
                <el-col :span="4">
                    <el-checkbox v-model="Options.calledOppor" @change="OptionsChange">拨打机会数</el-checkbox>
                </el-col>
                <el-col :span="4">
                    <el-checkbox v-model="Options.connected" @change="OptionsChange">接通量</el-checkbox>
                </el-col>
                <el-col :span="4">
                    <el-checkbox v-model="Options.connectionVolume" @change="OptionsChange">接通率</el-checkbox>
                </el-col>
                <el-col :span="4">
                    <el-checkbox v-model="Options.connectedOppor" @change="OptionsChange">机会接通量</el-checkbox>
                </el-col>
                <el-col :span="4">
                    <el-checkbox v-model="Options.calledEffective" @change="OptionsChange">有效量</el-checkbox>
                </el-col>
                <el-col :span="4">
                    <el-checkbox v-model="Options.calledTotal" @change="OptionsChange">总通话时长</el-checkbox>
                </el-col>
                <el-col :span="4">
                    <el-checkbox v-model="Options.avgCallTime" @change="OptionsChange">平均单通话时长</el-checkbox>
                </el-col>
                <el-col :span="4">
                    <el-checkbox v-model="Options.orderTotal" @change="OptionsChange">订单量</el-checkbox>
                </el-col>
                <el-col :span="4">
                    <el-checkbox v-model="Options.orderSuccess" @change="OptionsChange">成单量</el-checkbox>
                </el-col>
                <el-col :span="4">
                    <el-checkbox v-model="Options.conversionFirst" @change="OptionsChange">首咨转化率</el-checkbox>
                </el-col>
                <el-col :span="4">
                    <el-checkbox v-model="Options.orderSuccessOuter" @change="OptionsChange">21天以上成单量
                    </el-checkbox>
                </el-col>
                <el-col :span="4">
                    <el-checkbox v-model="Options.orderSuccessIn" @change="OptionsChange">21天内成单量</el-checkbox>
                </el-col>
                <el-col :span="4">
                    <el-checkbox v-model="Options.conversion21" @change="OptionsChange">21天内转化率</el-checkbox>
                </el-col>
              <el-col :span="4">
                  <el-checkbox v-model="Options.firstAdvisoryCount" @change="OptionsChange">首咨领取机会个数</el-checkbox>
              </el-col>
              <el-col :span="4">
                  <el-checkbox v-model="Options.stockAdvisoryCount" @change="OptionsChange">库存领取机会个数</el-checkbox>
              </el-col>
            </el-row>
        </el-row>
        <el-row type="flex" justify="start" :gutter="10">
            <el-col :offset="0.1">
                <el-form :inline="true">
                    <el-form-item label="时间区间">
                        <el-date-picker
                                v-model="startToEndTime"
                                type="daterange"
                                align="right"
                                value-format="yyyy-MM-dd"
                                unlink-panels
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                size="mini"
                                :picker-options="startToEndTimeOptions">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="部门" prop="deptId">
                        <el-cascader
                                :options="deptList"
                                :props="{ multiple: true, checkStrictly: true, label: 'title', value: 'id', children: 'children', emitPath: false}"
                                v-model="form.deptList"
                                clearable
                                size="mini"></el-cascader>
                    </el-form-item>
                    <el-form-item label="咨询师">
                        <el-select v-model="form.accountList" multiple class="condition-select" filterable
                                   placeholder="请选择"
                                   clearable
                                   size="mini">
                            <el-option v-for="item in adviserList" :key="item.id" :label="item.nickName"
                                       :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="咨询师状态">
                        <el-select v-model="form.accountStatus" multiple class="condition-select" filterable
                                   placeholder="请选择"
                                   clearable
                                   size="mini">
                            <el-option :value="1" label="在职"></el-option>
                            <el-option :value="2" label="离职"></el-option>
                            <el-option :value="0" label="暂停"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" size="mini" icon="el-icon-search" @click="doSearch"></el-button>
                        <el-button type="primary" size="mini" icon="el-icon-download" @click="handleDownload">下载</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <el-table
                :data="tableData" style="width: 100%;margin-bottom: 20px;" :row-key="getRowKeys" v-loading="loading"
                stripe
                :fit="true"
                :header-cell-style="{'text-align':'center'}"
                border max-height="500" ref="dataTab"
                :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
            <el-table-column prop="deptName" label="部门" fixed="left" min-width="200">
                <template #header>
                    <el-tooltip class="item" effect="dark" content="数据产生于哪个部门" placement="top-start">
                        <div>部门</div>
                    </el-tooltip>
                </template>
            </el-table-column>
          <el-table-column prop="deptStatus" label="部门状态" align="center">
            <template #header>
              <el-tooltip class="item" effect="dark" content="部门当前状态"
                          placement="top-start">
                <div>部门状态</div>
              </el-tooltip>
            </template>
            <template v-slot="scope">
              <span v-if="scope.row.deptStatus === 1">正常</span>
              <span v-else-if="scope.row.deptStatus === 0">停用</span>
            </template>
          </el-table-column>
            <el-table-column prop="nickName" label="销售姓名" fixed="left" align="center"
                             min-width="100">
                <template #header>
                    <el-tooltip class="item" effect="dark" content="销售姓名" placement="top-start">
                        <div>销售姓名</div>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column prop="status" label="人员状态" align="center">
                <template #header>
                    <el-tooltip class="item" effect="dark" content="人员当前状态(与产生数据时状态无关)"
                                placement="top-start">
                        <div>人员状态</div>
                    </el-tooltip>
                </template>
                <template v-slot="scope">
                    <span v-if="scope.row.status === 1">在职</span>
                    <span v-else-if="scope.row.status === 2">离职</span>
                    <span v-else-if="scope.row.status === 0">暂停</span>
                </template>
            </el-table-column>
            <el-table-column prop="revenue" v-if='Options.revenue' label="流水" align="right">
                <template #header>
                    <el-tooltip class="item" effect="dark" content="订单管理中所有已完成订单状态的缴费金额的总计"
                                placement="top-start">
                        <div>流水</div>
                    </el-tooltip>
                </template>
                <template v-slot="scope">
                    {{ formatMoney(scope.row.revenue) }}
                </template>
            </el-table-column>
            <el-table-column prop="repairRevenue" v-if='Options.repairRevenue' label="补录流水" align="right">
                <template #header>
                    <el-tooltip class="item" effect="dark"
                                content="补录订单中一审提交+二审提交+完成（未同步）的金额总和-审核驳回/拒绝的金额(实时数据)"
                                placement="top-start">
                        <div>补录流水</div>
                    </el-tooltip>
                </template>
                <template v-slot="scope">
                    {{ formatMoney(scope.row.repairRevenue) }}
                </template>
            </el-table-column>
            <el-table-column prop="opportunityCount" v-if='Options.opportunityCount' label="当前机会数" align="right"
                             min-width="100">
                <template #header>
                    <el-tooltip class="item" effect="dark"
                                content="当前时间截止，在某咨询师名下所有的机会总数，时间筛选后数据不会变化"
                                placement="top-start">
                        <div>当前机会数</div>
                    </el-tooltip>
                </template>
                <template v-slot="scope">
                    {{formatNumberWithCommas(scope.row.opportunityCount)}}
                </template>
            </el-table-column>
            <el-table-column prop="receiveFirst" v-if='Options.receiveFirst' label="索取首咨次数" align="right"
                             min-width="100">
                <template #header>
                    <el-tooltip class="item" effect="dark" content="领取首咨/分配首咨的机会次数统计"
                                placement="top-start">
                        <div>索取首咨次数</div>
                    </el-tooltip>
                </template>
                <template v-slot="scope">
                    {{formatNumberWithCommas(scope.row.receiveFirst)}}
                </template>
            </el-table-column>
            <el-table-column prop="receiveInventory" v-if='Options.receiveInventory' label="索取库存次数" align="right"
                             min-width="100">
                <template #header>
                    <el-tooltip class="item" effect="dark" content="领取库存/分配库存的机会次数统计"
                                placement="top-start">
                        <div>索取库存次数</div>
                    </el-tooltip>
                </template>
                <template v-slot="scope">
                    {{formatNumberWithCommas(scope.row.receiveInventory)}}
                </template>
            </el-table-column>
          <el-table-column prop="firstAdvisoryCount" v-if='Options.firstAdvisoryCount' label="首咨领取机会个数" align="right"
                           min-width="100">
            <template #header>
              <el-tooltip class="item" effect="dark" content="领取首资机会的个数"
                          placement="top-start">
                <div>首咨领取机会个数</div>
              </el-tooltip>
            </template>
            <template v-slot="scope">
              {{formatNumberWithCommas(scope.row.firstAdvisoryCount)}}
            </template>
          </el-table-column>
          <el-table-column prop="stockAdvisoryCount" v-if='Options.stockAdvisoryCount' label="库存领取机会个数" align="right"
                           min-width="100">
            <template #header>
              <el-tooltip class="item" effect="dark" content="领取库存机会的个数"
                          placement="top-start">
                <div>库存领取机会个数</div>
              </el-tooltip>
            </template>
            <template v-slot="scope">
              {{formatNumberWithCommas(scope.row.stockAdvisoryCount)}}
            </template>
          </el-table-column>
            <el-table-column prop="called" v-if='Options.called' label="拨打量" align="right">
                <template #header>
                    <el-tooltip class="item" effect="dark" content="直呼双呼的点击拨打次数统计" placement="top-start">
                        <div>拨打量</div>
                    </el-tooltip>
                </template>
                <template v-slot="scope">
                    {{formatNumberWithCommas(scope.row.called)}}
                </template>
            </el-table-column>
            <el-table-column prop="wechat" v-if='Options.wechat' label="微信添加量" min-width="100" align="right">
                <template #header>
                    <el-tooltip class="item" effect="dark" content="机会的微信添加数量统计" placement="top-start">
                        <div>微信添加量</div>
                    </el-tooltip>
                </template>
                <template v-slot="scope">
                    {{formatNumberWithCommas(scope.row.wechat)}}
                </template>
            </el-table-column>
            <el-table-column prop="calledTimes" v-if='Options.calledTimes' label="拨打频次" align="right">
                <template #header>
                    <el-tooltip class="item" effect="dark" content="拨打量÷拨打机会数的统计" placement="top-start">
                        <div>拨打频次</div>
                    </el-tooltip>
                </template>
                <template v-slot="scope">
                    <span v-if="scope.row.calledOppor">
                        {{ (scope.row.called / scope.row.calledOppor * 100).toFixed(2) }} %
                    </span>
                    <span v-else>0%</span>
                </template>
            </el-table-column>
            <el-table-column prop="calledOppor" v-if='Options.calledOppor' label="拨打机会数" align="right">
                <template #header>
                    <el-tooltip class="item" effect="dark" content="拨打机会总数的统计" placement="top-start">
                        <div>拨打机会数</div>
                    </el-tooltip>
                </template>
                <template v-slot="scope">
                    {{formatNumberWithCommas(scope.row.calledOppor)}}
                </template>
            </el-table-column>
            <el-table-column prop="connected" v-if='Options.connected' label="接通量" align="right">
                <template #header>
                    <el-tooltip class="item" effect="dark" content="拨打机会的接通量的统计" placement="top-start">
                        <div>接通量</div>
                    </el-tooltip>
                </template>
                <template v-slot="scope">
                    {{formatNumberWithCommas(scope.row.connected)}}
                </template>
            </el-table-column>
            <el-table-column prop="connectionVolume" v-if='Options.connectionVolume' label="接通率" align="right">
                <template #header>
                    <el-tooltip class="item" effect="dark" content="接通量÷拨打量" placement="top-start">
                        <div>接通率</div>
                    </el-tooltip>
                </template>
                <template v-slot="scope">
                    <span v-if="scope.row.called">
                        {{ (scope.row.connected / scope.row.called * 100).toFixed(2) }} %
                    </span>
                    <span v-else>0%</span>
                </template>
            </el-table-column>
            <el-table-column prop="connectedOppor" v-if='Options.connectedOppor' label="机会接通量" align="right">
                <template #header>
                    <el-tooltip class="item" effect="dark" content="接通了机会的个数总量(重复机会算一次)"
                                placement="top-start">
                        <div>机会接通量</div>
                    </el-tooltip>
                </template>
                <template v-slot="scope">
                    {{formatNumberWithCommas(scope.row.connectedOppor)}}
                </template>
            </el-table-column>
            <el-table-column prop="calledEffective" v-if='Options.calledEffective' label="有效量" align="right">
                <template #header>
                    <el-tooltip class="item" effect="dark" content="拨打接通成功>30s的通话数的统计"
                                placement="top-start">
                        <div>有效量</div>
                    </el-tooltip>
                </template>
                <template v-slot="scope">
                    {{formatNumberWithCommas(scope.row.calledEffective)}}
                </template>
            </el-table-column>
            <el-table-column prop="calledTotal" v-if='Options.calledTotal' label="总通话时长" align="right">
                <template #header>
                    <el-tooltip class="item" effect="dark" content="所有接通量的通话时长总计(实时数据)" placement="top-start">
                        <div>总通话时长</div>
                    </el-tooltip>
                </template>
                <template v-slot="scope">
                    {{ formateTime(scope.row.calledTotal) }}
                </template>
            </el-table-column>
            <el-table-column prop="avgCallTime" v-if='Options.avgCallTime' label="平均单通话时长" align="right">
                <template #header>
                    <el-tooltip class="item" effect="dark" content="总通话时长÷接通量" placement="top-start">
                        <div>平均单通话时长</div>
                    </el-tooltip>
                </template>
                <template v-slot="scope">
                    <span v-if="scope.row.connected">
                        {{ formateTime(scope.row.calledTotal / scope.row.connected) }}
                    </span>
                    <span v-else>00:00:00</span>
                </template>
            </el-table-column>
            <el-table-column prop="orderTotal" v-if='Options.orderTotal' label="订单量" align="right">
                <template #header>
                    <el-tooltip class="item" effect="dark" content="当前咨询师的所有状态的正式订单(不算补录订单)数量(实时数据)"
                                placement="top-start">
                        <div>订单量</div>
                    </el-tooltip>
                </template>
                <template v-slot="scope">
                    {{formatNumberWithCommas(scope.row.orderTotal)}}
                </template>
            </el-table-column>
            <el-table-column prop="orderSuccess" v-if='Options.orderSuccess' label="成单量" align="right">
                <template #header>
                    <el-tooltip class="item" effect="dark" content="已成交的正式订单的数量" placement="top-start">
                        <div>成单量</div>
                    </el-tooltip>
                </template>
                <template v-slot="scope">
                    {{formatNumberWithCommas(scope.row.orderSuccess)}}
                </template>
            </el-table-column>
            <el-table-column prop="conversionFirst" v-if='Options.conversionFirst' label="首咨转化率" align="center">
                <template #header>
                    <el-tooltip class="item" effect="dark" content="成单量÷领取首咨数" placement="top-start">
                        <div>首咨转化率</div>
                    </el-tooltip>
                </template>
                <template v-slot="scope">
                    <span v-if="scope.row.receiveFirst">
                        {{ (scope.row.orderSuccess / scope.row.receiveFirst * 100).toFixed(2) }} %
                    </span>
                    <span v-else>0%</span>
                </template>
            </el-table-column>
            <el-table-column prop="orderSuccessOuter" v-if='Options.orderSuccessOuter' label="21天以上成单量"
                             align="right">
                <template #header>
                    <el-tooltip class="item" effect="dark" content="支付时间-机会创建时间>21天的成交数量"
                                placement="top-start">
                        <div>21天以上成单量</div>
                    </el-tooltip>
                </template>
                <template v-slot="scope">
                    {{formatNumberWithCommas(scope.row.orderSuccessOuter)}}
                </template>
            </el-table-column>
            <el-table-column prop="orderSuccessIn" v-if='Options.orderSuccessIn' label="21天内成单量" align="right">
                <template #header>
                    <el-tooltip class="item" effect="dark" content="支付时间-机会创建时间≤21天的成交数量"
                                placement="top-start">
                        <div>21天内成单量</div>
                    </el-tooltip>
                </template>
                <template v-slot="scope">
                    {{formatNumberWithCommas(scope.row.orderSuccessIn)}}
                </template>
            </el-table-column>
            <el-table-column prop="conversion21" v-if='Options.conversion21' label="21天内转化率" align="right">
                <template #header>
                    <el-tooltip class="item" effect="dark" content="21天内成单量÷领取首咨数" placement="top-start">
                        <div>21天内转化率</div>
                    </el-tooltip>
                </template>
                <template v-slot="scope">
                    <span v-if="scope.row.receiveFirst">
                        {{ (scope.row.orderSuccessIn / scope.row.receiveFirst * 100).toFixed(2) }} %
                    </span>
                    <span v-else>0%</span>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import AnalysisCard from "../../components/AnalysisCard";
import * as deptApi from "@/api/system/dept";
import * as accountApi from "@/api/system/account";
import * as analysisApi from "@/api/crm/data/data-analysis";
import axios from "axios";

export default {
    props: {
        dataType: {
            type: Number,
            require: false,
            default: 1
        }
    },
    name: "Index",
    components: {AnalysisCard},
    data() {
        return {
            tableData: [],
            dataAnalysisData: {
                revenue: 0,
                receiveFirst: 0,
                calledEffective: 0,
                connected: 0,
                called: 0,
                calledTotal: 0,
                orderSuccess: 0,
                orderTotal: 0,
                repairRevenue: 0,
            },
            form: {accountStatus: [1]},
            startToEndTime: [this.formatTimes(new Date()), this.formatTimes(new Date())],
            adviserList: [],
            loading: true,
            removeDept: [],
            Options: {
                revenue: localStorage.getItem('dataAnalysis') ? JSON.parse(localStorage.getItem('dataAnalysis')).revenue : true, //流水
                repairRevenue: localStorage.getItem('dataAnalysis') ? JSON.parse(localStorage.getItem('dataAnalysis')).repairRevenue : true, //补录流水
                opportunityCount: localStorage.getItem('dataAnalysis') ? JSON.parse(localStorage.getItem('dataAnalysis')).opportunityCount : true, //当前机会数
                receiveFirst: localStorage.getItem('dataAnalysis') ? JSON.parse(localStorage.getItem('dataAnalysis')).receiveFirst : true, //领取首咨数
                receiveInventory: localStorage.getItem('dataAnalysis') ? JSON.parse(localStorage.getItem('dataAnalysis')).receiveInventory : true, //领取库存数
                called: localStorage.getItem('dataAnalysis') ? JSON.parse(localStorage.getItem('dataAnalysis')).called : true, //拨打量
                wechat: localStorage.getItem('dataAnalysis') ? JSON.parse(localStorage.getItem('dataAnalysis')).wechat : true, //微信添加量
                calledTimes: localStorage.getItem('dataAnalysis') ? JSON.parse(localStorage.getItem('dataAnalysis')).calledTimes : true, //拨打频次
                calledOppor: localStorage.getItem('dataAnalysis') ? JSON.parse(localStorage.getItem('dataAnalysis')).calledOppor : true, //拨打机会数
                connected: localStorage.getItem('dataAnalysis') ? JSON.parse(localStorage.getItem('dataAnalysis')).connected : true, //接通量
                connectionVolume: localStorage.getItem('dataAnalysis') ? JSON.parse(localStorage.getItem('dataAnalysis')).connectionVolume : true, //接通率
                connectedOppor: localStorage.getItem('dataAnalysis') ? JSON.parse(localStorage.getItem('dataAnalysis')).connectedOppor : true, //机会接通量
                calledEffective: localStorage.getItem('dataAnalysis') ? JSON.parse(localStorage.getItem('dataAnalysis')).calledEffective : true, //有效量
                calledTotal: localStorage.getItem('dataAnalysis') ? JSON.parse(localStorage.getItem('dataAnalysis')).calledTotal : true, //总通话时长
                avgCallTime: localStorage.getItem('dataAnalysis') ? JSON.parse(localStorage.getItem('dataAnalysis')).avgCallTime : true, //平均单通话时长
                orderTotal: localStorage.getItem('dataAnalysis') ? JSON.parse(localStorage.getItem('dataAnalysis')).orderTotal : true, //订单量
                orderSuccess: localStorage.getItem('dataAnalysis') ? JSON.parse(localStorage.getItem('dataAnalysis')).orderSuccess : true, //成单量
                conversion: localStorage.getItem('dataAnalysis') ? JSON.parse(localStorage.getItem('dataAnalysis')).conversion : true, //首咨转化率
                orderSuccessOuter: localStorage.getItem('dataAnalysis') ? JSON.parse(localStorage.getItem('dataAnalysis')).orderSuccessOuter : true, //21天以上成单量
                orderSuccessIn: localStorage.getItem('dataAnalysis') ? JSON.parse(localStorage.getItem('dataAnalysis')).orderSuccessIn : true, //21天内成单量
                conversion21: localStorage.getItem('dataAnalysis') ? JSON.parse(localStorage.getItem('dataAnalysis')).conversion21 : true, //21天内成单量
                conversionFirst: localStorage.getItem('dataAnalysis') ? JSON.parse(localStorage.getItem('dataAnalysis')).conversionFirst : true, //21天内成单量
              firstAdvisoryCount: localStorage.getItem('dataAnalysis') ? JSON.parse(localStorage.getItem('dataAnalysis')).firstAdvisoryCount : true, //首咨领取机会个数
              stockAdvisoryCount: localStorage.getItem('dataAnalysis') ? JSON.parse(localStorage.getItem('dataAnalysis')).stockAdvisoryCount : true, //库存领取机会个数
            },
            deptList: [],
            showDeptList: [],
            startToEndTimeOptions: {
                shortcuts: [
                    {
                        text: '今天',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            picker.$emit('pick', [start, end]);
                        },
                    },
                    {
                        text: '昨天',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24);
                            end.setTime(end.getTime() - 3600 * 1000 * 24);
                            picker.$emit('pick', [start, end]);
                        },
                    },
                    {
                        text: '本周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            const firstDayOfWeek = start.getDate() - start.getDay() + (start.getDay() === 0 ? -6 : 1);
                            start.setDate(firstDayOfWeek);
                            picker.$emit('pick', [start, end]);
                        },
                    },
                    {
                        text: '本月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date(end.getFullYear(), end.getMonth(), 1);
                            picker.$emit('pick', [start, end]);
                        },
                    },
                ],
                disabledDate(time) {
                    return time.getTime() > Date.now();
                }
            }
        }
    },
    methods: {
      zhengjing(){
        const dataKey = document.cookie.replace(/(?:(?:^|.*;\s*)dataKey\s*\=\s*([^;]*).*$)|^.*$/, "$1");
        console.log(dataKey)
        return dataKey !== 'zhengjing';
      },
        handleDownload(){
            if (this.startToEndTime) {
                this.form.startTime = this.startToEndTime[0]
                this.form.endTime = this.startToEndTime[1]
            }
            axios({
                method: "post",
                url: "/api/manage/crm/data-analysis/downloadAnalysis",
                headers: {
                    "version": localStorage.getItem("_version"),
                },
                responseType: 'blob',
                data:this.form,
            }).then(res => {
                const blob = new Blob([res.data]);
                const fileName = '数据分析数据.xlsx';
                const elink = document.createElement('a')
                elink.download = fileName;
                elink.style.display = 'none';
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
            }).catch(error => {
                this.$message.error("导出模板失败");
            })
        },
        formatNumberWithCommas(num) {
            if(num){
                // 使用正则表达式将数字格式化为千分位格式
                return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            return 0
        },
        formatMoney(money) {
            if (money) {
                return (money / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            return '0.00'
        },
        formateTime(time) {
            if (time < 1) {
                return '00:00:00'
            }
            const h = parseInt(time / 3600)
            const minute = parseInt(time / 60 % 60)
            const second = Math.ceil(time % 60)

            const hours = h < 10 ? '0' + h : h
            const formatSecond = second > 59 ? 59 : second
            return `${hours > 0 ? `${hours}:` : ''}${minute < 10 ? '0' + minute : minute}:${formatSecond < 10 ? '0' + formatSecond : formatSecond}`
        },
        sumAnalysis() {
            if (this.startToEndTime) {
                this.form.startTime = this.startToEndTime[0]
                this.form.endTime = this.startToEndTime[1]
            }
            analysisApi.sumAnalysis(this.form).then(res => {
                this.dataAnalysisData = res.data
            })
        },
        keyboard(e) {
            if (e.keyCode === 13) {
                this.doSearch()
            }
        },
        findDept() {
            deptApi.treeDept().then(res => {
                this.deptList = res.data
            });
        },
        getAdviserList: function () {
            accountApi.adviserList().then(res => {
                if (res.success) {
                    this.adviserList = res.data
                }
            })
        },
        getRowKeys(row) {
            return row.deptId + '-' + row.accountId + '-' + row.parentId
        },
        /**
         *加载表格主体数据
         */
        doSearch() {
            if (this.startToEndTime) {
                this.form.startTime = this.startToEndTime[0]
                this.form.endTime = this.startToEndTime[1]
            }
            analysisApi.analysis(this.form).then(res => {
                this.tableData = res.data
                this.loading = false
                this.sumAnalysis()
            })
        },
        toPersonInfo(accountId, accountName) {
            let query = {accountId: accountId, accountName: accountName}
            this.$router.push({path: '/DataAnalysis/PersonInfo', query: query})
        },
        /**
         * 选择项
         * */
        OptionsChange() {
            let Options = JSON.stringify(this.Options);
            localStorage.setItem('Options', Options);
        },
        formatTimes(date) {
            // 获取各种类型当前时间
            let year = date.getFullYear();    // 获取年
            let month = date.getMonth() + 1;   //获取当前月
            month = month < 10 ? '0' + month : month
            let day = date.getDate();   //日
            day = day < 10 ? '0' + day : day
            return year + "-" + month + "-" + day
        },
    },
    mounted() {
        this.getAdviserList()
        this.doSearch()
        this.findDept()
        this.sumAnalysis()
    },
    updated() {
        if (this.$refs.dataTab && this.tableData.length > 0) {
            this.$refs.dataTab.toggleRowExpansion(this.tableData[0], true);
        }
    },
}
</script>

<style scoped>
</style>
